<template>
  <div class="page page--document">
    <section class="section" data-sec="1" id="sec1">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12">
            <p class="text tc-silver fs-14 fs-md-18 mb-3 mb-md-4">{{ cc.text[0] + ' ' + cc.date }}</p>
            <h1 class="title fs-lg-40 fw-500">{{ cc.title }}</h1>
            <p class="text tc-silver mb-3 mb-md-4">{{ cc.text[1] }}</p>
            <p class="text tc-silver fs-16 fs-md-20">{{ cc.text[2] + ' ' + cc.version }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <hr class="line line--silver">
          </div>
        </div>
      </div>
    </section>
    <section class="section" data-sec="2" id="sec2">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="document">
              <doc-section :content="cc.content" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import DocSection from '@/components/docs/DocumentSections'
export default {
  components: {
    DocSection
  },
  computed: {
    cc () {
      return this.$t('pages.documents.amlkyc')
    }
  },
  metaInfo () {
    const meta = this.$t('meta.documents.child.amlkyc')
    return {
      titleTemplate: '%s | ' + meta.name,
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
